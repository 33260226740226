"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeRoomDetail = void 0;
const react_1 = __importStar(require("react"));
const react_i18next_1 = require("react-i18next");
const react_router_dom_1 = require("react-router-dom");
const Modal_1 = require("../components/Modal");
const FullView_1 = require("../components/Modal/FullView");
const HomeRoomDetail = ({ sellingname, propertyId }) => {
    const [previewObj, setPreviewObj] = (0, react_1.useState)(null);
    const { locale } = (0, react_router_dom_1.useParams)();
    const [isPreview, setPreview] = (0, react_1.useState)(false);
    const { t, i18n } = (0, react_i18next_1.useTranslation)(["search"]);
    if (!sellingname) {
        return react_1.default.createElement("div", null, t("Property not found."));
    }
    (0, react_1.useEffect)(() => {
        if (isPreview) {
            $("#viewFullModal").modal("show");
        }
    }, [isPreview]);
    (0, react_1.useEffect)(() => {
        i18n.changeLanguage(locale);
    }, []);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        isPreview ? (react_1.default.createElement(FullView_1.FullViewModal, { previewImages: previewObj?.previewImages
                ? previewObj?.previewImages
                : ["default img url"], previewIndex: previewObj?.previewIndex ? previewObj?.previewIndex : 0, isOpen: isPreview, onClose: () => setPreview(false) })) : null,
        react_1.default.createElement(Modal_1.RoomDetailContent, { sellingname: sellingname, isRoomCard: true, setPreview: setPreview, setPreviewObj: setPreviewObj, propertyId: propertyId })));
};
exports.HomeRoomDetail = HomeRoomDetail;
// if (document.getElementsByClassName("home-page-room-details")) {
//   const items = document.getElementsByClassName("home-page-room-details");
//   Array.prototype.forEach.call(items, function (item) {
//     ReactDOM.render(
//       <I18nextProvider i18n={i18n}>
//         <BrowserRouter>
//           <Routes>
//             <Route
//               path="/:locale/:region/*"
//               element={
//                 <HomeRoomDetail
//                   sellingname={item.dataset.sellingname}
//                   propertyId={item.dataset.propertyid}
//                 />
//               }
//             />
//           </Routes>
//         </BrowserRouter>
//       </I18nextProvider>,
//       item
//     );
//   });
// }
