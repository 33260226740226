"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
const Sentry = __importStar(require("@sentry/react"));
const tracing_1 = require("@sentry/tracing");
Sentry.init({
    dsn: "https://4c1f8c1f84c7445ea8cbc9475b2b168d@sentry.appicidea.com/6",
    integrations: [new tracing_1.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.MIX_SENTRY_ENV || 'development',
    release: process.env.MIX_SENTRY_REL || 'default',
    enabled: process.env.NODE_ENV !== 'development',
    beforeSend: (event) => {
        if (window?.location?.hostname === 'localhost') {
            return null;
        }
        return event;
    },
    ignoreErrors: [
        'Request failed with status code 403',
        'ResizeObserver loop limit exceeded',
        'AbortError: The user aborted a request',
        'Request aborted',
        'NetworkError when attempting to fetch resource',
        'There was a network error',
        'Network Error',
        'ResizeObserver loop completed with undelivered notifications',
    ],
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
});
__exportStar(require("./pages"), exports);
__exportStar(require("./RoomModal"), exports);
